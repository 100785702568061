<!--
 * @Description: 企业网站模板
 -->
<template>
  <div class="container">
    <div class="banner">
      <el-carousel trigger="click" height="25vw">
        <el-carousel-item v-for="item in bannerList" :key="item.url">
          <!-- <img
            src="http://dmcrm.oss-cn-hangzhou.aliyuncs.com/upload/20240522/e6b81c600bae38dc6957367e9a2675ca.png"
            alt=""
          /> -->
          <img :src="item.url" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="goodsList">
      <p class="title">覆盖各行各业的企业网站模板</p>
      <div class="goods">
        <el-row>
          <el-col :span="8" v-for="item in list" :key="item.id">
            <div class="card" @click="goodsDetail(item.id)">
              <img :src="item.image" alt="" />
              <p>{{ item.storeName }}</p>
              <p>
                已售：{{ item.ficti + item.sales }}
                <span>¥{{ item.price }}</span>
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      bannerList: [
        {
          url:
            "https://yxpt-prd.oss-cn-hangzhou.aliyuncs.com/2024/08/01/df3c01e84afdfac571026c8e8ec7ac70.jpg",
        },
        {
          url:
            "https://yxpt-prd.oss-cn-hangzhou.aliyuncs.com/2024/08/01/4db0426918d7e3371e3c988f1d6a8547.jpg",
        },
        {
          url:
            "https://yxpt-prd.oss-cn-hangzhou.aliyuncs.com/2024/08/01/f9039b4ab357db1a55d3b4a52147b5e1.jpg",
        },
      ],
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios
        .get("/api/front/products?cid=" + 742, {})
        .then((res) => {
          // 获取种类的响应
          console.log("@@获取商品所有种类的响应：", res);
          this.list = res.data.data.list;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    goodsDetail(id) {
      this.$router.push({ path: "/web/goodsDetail", query: { goodsId: id } });
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  padding: 0;
  .banner {
    img {
      width: 100%;
      height: 25vw;
    }
  }
  .goodsList {
    width: 100%;
    padding: 0 160px;
    .title {
      font-family: "PingFang SC";
      font-size: 32px;
      font-weight: 500;
      margin: 50px 0;
      text-align: center;
    }
    .goods {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .el-col {
        margin-bottom: 20px;
      }
      // .el-col:nth-child(2n) {
      //   .card {
      //     margin: 0 auto;
      //   }
      // }
      // .el-col:nth-child(3n) {
      //   .card {
      //     float: right;
      //   }
      // }
      // .el-col:nth-child(4n) {
      //   .card {
      //     float: left;
      //   }
      // }
      .card {
        text-align: left;
        width: 360px;
        height: 320px;
        border-radius: 8px;
        margin: 0 auto;
        background: #ffffff;
        cursor: pointer;
        img {
          width: 340px;
          height: 218.45px;
          margin: 11px 10px;
        }
        p {
          margin-left: 20px;
          margin: 4px 24px 0 20px;
          line-height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            float: right;
            color: rgba(255, 0, 0, 0.85);
            font-family: "PingFang SC";
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
